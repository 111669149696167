import { Icon } from '#components'

export default defineComponent({
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    return () => (
      <button
        class={
          props.disabled
            ? 'pointer-events-none cursor-default opacity-50'
            : 'cursor-pointer'
        }
        disabled={props.disabled}
        onClick={(event) => emit('toggle', event)}
      >
        {props.isExpanded ? (
          <Icon name="chevron-down" />
        ) : (
          <Icon name="chevron-right" />
        )}
      </button>
    )
  },
})
